// @flow

import { computed, action } from 'mobx'
import { RootStore } from '../RootStore'

export default class UserStore {
  +rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @computed get loggedIn(): boolean {
    return !!this.rootStore.authenticationStore.token
  }

  @action async performLogout() {
    return this.rootStore.authenticationStore.performLogout()
  }
}
