// @flow

import { computed, observable } from 'mobx'

export type LotStatus = 'available' | 'optioned' | 'booked' | 'sold'
export type LotLoan =
  | 'no_action_initiated'
  | 'loan_simulation_done'
  | 'loan_granted'
export type LotVat = 20 | 5.5
type LotCustomerSummary = {
  customerId: string,
  firstName: string,
  lastName: string,
}

export default class Lot {
  +lotId: string
  +label: string
  +orientation: string
  +area: number
  +typology: string
  +floor: number

  @observable status: LotStatus = 'available'
  @observable statusChangedAt: Date = new Date()
  @observable priceIncludingTaxes20: number = 0
  @observable vat: LotVat = 20
  @observable loan: LotLoan = 'no_action_initiated'
  @observable customer: ?LotCustomerSummary = null

  constructor(
    lotId: string,
    status: LotStatus,
    statusChangedAt: Date,
    label: string,
    priceIncludingTaxes20: number,
    orientation: string,
    area: number,
    typology: string,
    floor: number,
    commercialOffer: ?{
      vat: LotVat,
      loan: LotLoan,
      customer: LotCustomerSummary,
    },
  ) {
    this.lotId = lotId
    this.status = status
    this.statusChangedAt = statusChangedAt
    this.label = label
    this.priceIncludingTaxes20 = priceIncludingTaxes20
    this.orientation = orientation
    this.area = area
    this.typology = typology
    this.floor = floor

    if (commercialOffer) {
      this.vat = commercialOffer.vat
      this.loan = commercialOffer.loan
      this.customer = commercialOffer.customer
    }
  }

  is(lookupId: string): boolean {
    return this.lotId === lookupId
  }

  resetCommercialOffer() {
    this.loan = 'no_action_initiated'
    this.customer = null
  }

  updateCommercialOffer(
    vat: LotVat,
    loan: LotLoan,
    customerId: string,
    firstName: string,
    lastName: string,
  ) {
    this.vat = vat
    this.loan = loan
    this.customer = {
      customerId: customerId,
      firstName: firstName,
      lastName: lastName,
    }
  }

  updateFinancialInformations(vat: LotVat, loan: LotLoan): void {
    this.vat = vat
    this.loan = loan
  }

  updateStatus(status: LotStatus): void {
    const now = new Date()

    this.status = status
    this.statusChangedAt = now
  }

  updatePrice(priceIncludingTaxes20: number): void {
    this.priceIncludingTaxes20 = priceIncludingTaxes20
  }

  updateCustomer(firstName: string, lastName: string): void {
    if (!this.customer) {
      throw new Error('No current customer for updating')
    }
    this.customer.firstName = firstName
    this.customer.lastName = lastName
  }

  switchVat(): void {
    this.vat = this.vat === 20 ? 5.5 : 20
  }

  @computed get visiblePrice(): number {
    return this.vat === 20
      ? this.priceIncludingTaxes20
      : (this.priceIncludingTaxes20 - this.priceIncludingTaxes20 / 6) * 1.055
  }

  @computed get loanLabel(): ?string {
    if (this.loan === 'no_action_initiated') {
      return null
    } else if (this.loan === 'loan_simulation_done') {
      return 'Simulation'
    } else if (this.loan === 'loan_granted') {
      return 'Prêt obtenu'
    } else {
      return null
    }
  }

  @computed get vatIsFixed(): boolean {
    return this.status === 'booked' || this.status === 'sold'
  }

  @computed get isEditable(): boolean {
    return this.status !== 'sold'
  }

  @computed get faded(): boolean {
    return this.status === 'unavailable'
  }
}
