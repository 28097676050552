// @flow

import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { CusthomeTextLogo } from '@custhome/seller-guidelines'
import LoginForm from './LoginForm'
import AuthenticationStore from '../../Stores/AuthenticationStore'
import styled from 'styled-components'

const LoginPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
`

const WrapperLogo = styled.div`
  margin-bottom: 10px;
  width: 290px;
  height: 50px;
`

export default inject('authenticationStore')(
  observer(
    ({ authenticationStore }: { authenticationStore: AuthenticationStore }) => (
      <LoginPageLayout>
        <WrapperLogo>
          <CusthomeTextLogo />
        </WrapperLogo>
        <LoginForm authenticationStore={authenticationStore} />
      </LoginPageLayout>
    ),
  ),
)
