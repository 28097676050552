// @flow

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import React from 'react'
import ReactDOM from 'react-dom'
import { initErrorReportingAgent } from './ErrorReporting'
import SellerApplication from './SellerApplication'

const sentryDSN = process.env.SENTRY_DSN
if (!sentryDSN) {
  throw new Error('The sentry dsn environment variable is missing')
}
const buildEnvironment = process.env.BUILD_ENVIRONMENT
if (!buildEnvironment) {
  throw new Error('The build environment environment variable is missing')
}
const applicationName = process.env.APPLICATION_NAME
if (!applicationName) {
  throw new Error('The application name environment variable is missing')
}
const applicationVersion = process.env.APPLICATION_VERSION
if (!applicationVersion) {
  throw new Error('The application version environment variable is missing')
}

initErrorReportingAgent(
  sentryDSN,
  buildEnvironment,
  applicationName,
  applicationVersion,
)

const rootElement = document.getElementById('root')
if (!rootElement) {
  throw new Error('Unable to find the #root element')
}

ReactDOM.render(
  <React.Fragment>
    <SellerApplication />
  </React.Fragment>,
  rootElement,
)
