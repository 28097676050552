// @flow

import * as React from 'react'
import styled from 'styled-components'
import {
  DragDropKanban,
  DroppableColumn,
  DraggableCard,
  SelectRadio,
  RobotoMedium,
  Colors,
  Link,
  DialogModal,
  LotPriceEdition,
  FinancialInformations,
  CustomerSelection,
  CustomerInformationsForm,
  Tabs,
  Tab,
} from '@custhome/seller-guidelines'
import ApplicationLayout from '../../ApplicationLayout'
import { inject, observer } from 'mobx-react'
import LotsStore from '../../Stores/LotsStore'
import { type LotStatus, type LotVat, type LotLoan } from '../../Domain/Lot'
import ProgramsStore from '../../Stores/ProgramsStore'
import routes from '../../Routes'
import RouterStore from '../../Stores/RouterStore'

const LotManagementLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const FiltersContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 30px 0 30px;
`

const LabelFilters = styled.div`
  text-shadow: 0 1px 0 ${Colors.white};
  font-family: ${RobotoMedium.fontFamily};
  font-size: 14px;
  font-weight: ${RobotoMedium.fontWeight};
  text-transform: uppercase;
  color: ${Colors.blackUltraBold};
  margin-right: 8px;
`

const KanbanContainer = styled.div`
  display: flex;
  height: 100%;
  margin: 25px 0 0 0;
  overflow-y: auto;
`

const ResetFilters = styled.div`
  color: ${Colors.green};
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  text-shadow: 0 1px 0 white;

  &:hover {
    text-decoration: underline;
  }
`

export default inject('lotsStore', 'programsStore', 'routerStore')(
  observer(
    ({
      lotsStore,
      programsStore,
      routerStore,
    }: {
      lotsStore: LotsStore,
      programsStore: ProgramsStore,
      routerStore: RouterStore,
    }) => {
      const onDrop = (
        lotId: string,
        columnStatus: LotStatus,
        lotStatus: LotStatus,
      ) => {
        if (
          (columnStatus === 'optioned' || columnStatus === 'booked') &&
          lotStatus === 'available'
        ) {
          lotsStore.openModalAssociation(lotId, columnStatus)
        } else {
          lotsStore.updateLotStatus(lotId, columnStatus)
        }
      }
      const program = lotsStore.programId
        ? programsStore.getProgram(lotsStore.programId)
        : null
      const onClickCard = (lotId: string) => {
        lotsStore.openEditModal(lotId)
      }
      const onSubmitPriceEdition = (price: number) => {
        lotsStore.editPrice(price)
      }
      const onEditFinancialInformations = (
        vat: LotVat,
        loan: LotLoan,
        status: LotStatus,
      ) => {
        lotsStore.editFinancialInformations(vat, loan, status)
      }
      const onSubmitLotAssociation = (
        vat: LotVat,
        loan: LotLoan,
        status: LotStatus,
      ) => {
        lotsStore.updateLotAssociation(vat, loan, status)
      }

      const onCustomerSelected = (customerId: string) => {
        lotsStore.onCustomerSelected(customerId)
      }

      const onEditCustomerInformations = ({
        emailValue,
        firstNameValue,
        lastNameValue,
        phoneValue,
        addressValue,
        zipCodeValue,
        cityValue,
      }: {
        emailValue: string,
        firstNameValue: string,
        lastNameValue: string,
        phoneValue: string,
        addressValue: string,
        zipCodeValue: string,
        cityValue: string,
      }) => {
        lotsStore.editCustomerInformations({
          emailValue,
          firstNameValue,
          lastNameValue,
          phoneValue,
          addressValue,
          zipCodeValue,
          cityValue,
        })
      }

      const onCustomerValidate = ({
        emailValue,
        firstNameValue,
        lastNameValue,
        phoneValue,
        addressValue,
        zipCodeValue,
        cityValue,
      }: {
        emailValue: string,
        firstNameValue: string,
        lastNameValue: string,
        phoneValue: string,
        addressValue: string,
        zipCodeValue: string,
        cityValue: string,
      }) => {
        lotsStore.onCustomerValidate({
          emailValue,
          firstNameValue,
          lastNameValue,
          phoneValue,
          addressValue,
          zipCodeValue,
          cityValue,
        })
      }

      return (
        <ApplicationLayout
          breadcrumb={
            program
              ? [
                  <Link
                    key={program.id}
                    view={routes['lots_management_page']}
                    params={{ programId: program.id }}
                    router={routerStore}
                  >
                    {program.label}
                  </Link>,
                ]
              : []
          }
        >
          <LotManagementLayout>
            <FiltersContainer>
              <LabelFilters>Filtres</LabelFilters>

              {lotsStore.filters.map(oneFilter => {
                return (
                  <SelectRadio
                    key={oneFilter.id}
                    id={oneFilter.id}
                    label={oneFilter.label}
                    comparator={oneFilter.valueComparator}
                    choices={oneFilter.choices}
                    onChoiceClick={(id, value) =>
                      lotsStore.updateFilters(id, value)
                    }
                    values={oneFilter.values}
                  />
                )
              })}

              {lotsStore.atLeastOneFilterIsActive ? (
                <ResetFilters onClick={() => lotsStore.resetFilterSet()}>
                  Réinitialiser les filtres
                </ResetFilters>
              ) : null}
            </FiltersContainer>

            <KanbanContainer>
              <DragDropKanban>
                {lotsStore.columns.map((column, index) => {
                  const lotsForCurrentStatus =
                    lotsStore.lotsByStatus[column.status] || []

                  return (
                    <DroppableColumn
                      key={column.status}
                      columnStatus={column.status}
                      title={`${column.title}\u00A0(${lotsForCurrentStatus.length})`}
                      collapsable={column.collapsable}
                      onDrop={onDrop}
                      onSwitch={lotsStore.switchVat}
                      vat20={lotsStore.vat20}
                    >
                      {lotsForCurrentStatus.map(lot => (
                        <DraggableCard
                          key={lot.lotId}
                          toColumn={column.to}
                          lotId={lot.lotId}
                          status={lot.status}
                          label={lot.label}
                          vat={lot.vat}
                          typology={lot.typology}
                          area={lot.area}
                          orientation={lot.orientation}
                          floor={lot.floor}
                          onClickCard={lot.isEditable ? onClickCard : null}
                          visiblePrice={lot.visiblePrice}
                          loanLabel={lot.loanLabel}
                          vatIsFixed={lot.vatIsFixed}
                          lotStatus={lot.status}
                          faded={lot.faded}
                          customerNames={
                            lot.customer
                              ? {
                                  firstName: lot.customer.firstName,
                                  lastName: lot.customer.lastName,
                                }
                              : null
                          }
                        />
                      ))}
                    </DroppableColumn>
                  )
                })}
              </DragDropKanban>
            </KanbanContainer>
          </LotManagementLayout>

          {lotsStore.lotEditing ? (
            <DialogModal
              title={lotsStore.lotEditing.lot.label}
              onClose={lotsStore.closeEditModal}
              subTitle={
                lotsStore.lotEditing.lot.status === 'available' ||
                lotsStore.lotEditing.lot.status === 'unavailable'
                  ? 'Edition du lot'
                  : 'Informations de vente'
              }
            >
              {lotsStore.lotEditing.lot.status === 'available' ||
              lotsStore.lotEditing.lot.status === 'unavailable' ? (
                <LotPriceEdition
                  onSubmit={onSubmitPriceEdition}
                  priceIncludingTaxes20={
                    lotsStore.lotEditing.lot.priceIncludingTaxes20
                  }
                  errors={lotsStore.lotEditing.errors}
                />
              ) : (
                <Tabs>
                  <Tab id="customer" title="Client">
                    <CustomerInformationsForm
                      email={
                        lotsStore.lotEditing.customer
                          ? lotsStore.lotEditing.customer.email
                          : ''
                      }
                      firstName={
                        lotsStore.lotEditing.customer
                          ? lotsStore.lotEditing.customer.firstName
                          : ''
                      }
                      lastName={
                        lotsStore.lotEditing.customer
                          ? lotsStore.lotEditing.customer.lastName
                          : ''
                      }
                      phone={
                        lotsStore.lotEditing.customer
                          ? lotsStore.lotEditing.customer.phone
                          : ''
                      }
                      address={
                        lotsStore.lotEditing.customer
                          ? lotsStore.lotEditing.customer.address
                          : ''
                      }
                      zipCode={
                        lotsStore.lotEditing.customer
                          ? lotsStore.lotEditing.customer.zipCode
                          : ''
                      }
                      city={
                        lotsStore.lotEditing.customer
                          ? lotsStore.lotEditing.customer.city
                          : ''
                      }
                      customerId={
                        lotsStore.lotEditing.customer
                          ? lotsStore.lotEditing.customer.customerId
                          : ''
                      }
                      customers={[]}
                      fetchLoadedCustomer={null}
                      errors={lotsStore.lotEditing.errors}
                      labelButton="Enregistrer"
                      onSubmit={onEditCustomerInformations}
                    />
                  </Tab>
                  <Tab id="financial" title="Finance">
                    <FinancialInformations
                      currentVat={lotsStore.lotEditing.lot.vat}
                      currentLoan={lotsStore.lotEditing.lot.loan}
                      labelButton="Enregistrer"
                      status={lotsStore.lotEditing.lot.status}
                      onSubmit={onEditFinancialInformations}
                      priceIncludingTaxes20={
                        lotsStore.lotEditing.lot.priceIncludingTaxes20
                      }
                    />
                  </Tab>
                </Tabs>
              )}
            </DialogModal>
          ) : null}

          {lotsStore.lotAssociation ? (
            <DialogModal
              title={lotsStore.lotAssociation.lot.label}
              onClose={lotsStore.closeAssociationModal}
              subTitle={
                lotsStore.lotAssociation.step === 'customer_selection'
                  ? 'Association du client'
                  : lotsStore.lotAssociation.step === 'customer_informations'
                  ? 'Informations du client'
                  : 'Informations de vente'
              }
              steps={[
                'customer_selection',
                'customer_informations',
                'financial_informations',
              ]}
              stepActive={lotsStore.lotAssociation.step}
            >
              {lotsStore.lotAssociation.step === 'customer_selection' ? (
                <CustomerSelection
                  onNewCustomer={lotsStore.onNewCustomer}
                  customers={[...lotsStore.lotAssociation.customerSummaries]}
                  onCustomerSelected={onCustomerSelected}
                />
              ) : lotsStore.lotAssociation.step === 'customer_informations' ? (
                <CustomerInformationsForm
                  customers={[...lotsStore.lotAssociation.customerSummaries]}
                  errors={lotsStore.lotAssociation.errors}
                  email={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.email || ''
                      : ''
                  }
                  firstName={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.firstName || ''
                      : ''
                  }
                  lastName={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.lastName || ''
                      : ''
                  }
                  phone={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.phone || ''
                      : ''
                  }
                  address={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.address || ''
                      : ''
                  }
                  zipCode={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.zipCode || ''
                      : ''
                  }
                  city={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.city || ''
                      : ''
                  }
                  customerId={
                    lotsStore.lotAssociation.customer
                      ? lotsStore.lotAssociation.customer.customerId
                      : null
                  }
                  fetchLoadedCustomer={onCustomerSelected}
                  labelButton="Valider"
                  onSubmit={onCustomerValidate}
                />
              ) : (
                <FinancialInformations
                  currentVat={lotsStore.lotAssociation.lot.vat}
                  currentLoan={lotsStore.lotAssociation.lot.loan}
                  labelButton={
                    lotsStore.lotAssociation.nextStatus === 'optioned'
                      ? 'Optionner'
                      : 'Réserver'
                  }
                  status={lotsStore.lotAssociation.nextStatus}
                  onSubmit={onSubmitLotAssociation}
                  priceIncludingTaxes20={
                    lotsStore.lotAssociation.lot.priceIncludingTaxes20
                  }
                />
              )}
            </DialogModal>
          ) : null}
        </ApplicationLayout>
      )
    },
  ),
)
