// @flow

import FilterSet from './Filter/FilterSet'
import FilterDefinition from './Filter/FilterDefinition'
import FilterConstraint from './Filter/FilterConstraint'
import Lot from './Lot'

export const lotFilterSetBuilder = (): FilterSet<Lot> => {
  return FilterSet.fromFilterDefinitions([
    new FilterDefinition(
      'typology',
      'Typologies',
      [
        new FilterConstraint('T1', 'T1'),
        new FilterConstraint('T2', 'T2'),
        new FilterConstraint('T3', 'T3'),
        new FilterConstraint('T4', 'T4'),
      ],
      (a: string, b: string) => a === b,
      (lot: Lot, value: string) => lot.typology === value,
    ),

    new FilterDefinition(
      'area',
      'Surfaces',
      [
        new FilterConstraint({ from: 0, to: 30 }, '< 30m²'),
        new FilterConstraint({ from: 30, to: 40 }, '30-40m²'),
        new FilterConstraint({ from: 40, to: 50 }, '40-50m²'),
        new FilterConstraint({ from: 50, to: 60 }, '50-60m²'),
        new FilterConstraint({ from: 60, to: 70 }, '60-70m²'),
        new FilterConstraint({ from: 70, to: 80 }, '70-80m²'),
        new FilterConstraint({ from: 80, to: Infinity }, '> 80m²'),
      ],
      (a: {| from: number, to: number |}, b: {| from: number, to: number |}) =>
        a.from === b.from && a.to === b.to,
      (lot: Lot, value: {| from: number, to: number |}) =>
        lot.area >= value.from && lot.area <= value.to,
    ),

    new FilterDefinition(
      'orientation',
      'Orientations',
      [
        new FilterConstraint('N', 'Nord'),
        new FilterConstraint('NE', 'Nord-Est'),
        new FilterConstraint('NO', 'Nord-Ouest'),
        new FilterConstraint('E', 'Est'),
        new FilterConstraint('S', 'Sud'),
        new FilterConstraint('SE', 'Sud-Est'),
        new FilterConstraint('SO', 'Sud-Ouest'),
        new FilterConstraint('O', 'Ouest'),
      ],
      (a: string, b: string) => a === b,
      (lot: Lot, value: string) => lot.orientation === value,
    ),

    new FilterDefinition(
      'floor',
      'Étages',
      [
        new FilterConstraint(0, 'RDC'),
        new FilterConstraint(1, 'R+1'),
        new FilterConstraint(2, 'R+2'),
        new FilterConstraint(3, 'R+3'),
        new FilterConstraint(4, 'R+4'),
      ],
      (a: number, b: number) => a === b,
      (lot: Lot, value: string) => lot.floor === value,
    ),
  ])
}
