// @flow

import { action, observable, runInAction } from 'mobx'
import { RootStore } from '../RootStore'
import Program from '../Domain/Program'

export default class ProgramsStore {
  +rootStore: RootStore

  @observable programs: Program[] = []

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action async fetchPrograms() {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      '/api/seller/programs',
    )

    runInAction(() => {
      this.programs = response.data.map(
        oneProgramPayload =>
          new Program(
            oneProgramPayload.programId,
            oneProgramPayload.label,
            oneProgramPayload.location,
            oneProgramPayload.totalNumberOfLot,
          ),
      )
    })
  }

  getProgram(programId: string): ?Program {
    return this.programs.find(oneProgram => oneProgram.id === programId)
  }
}
