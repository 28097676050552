// @flow

import { action, observable, runInAction, computed } from 'mobx'
import { RootStore } from '../RootStore'
import Lot, { type LotStatus, type LotLoan, type LotVat } from '../Domain/Lot'
import FilterSet, { type FilterDescription } from '../Domain/Filter/FilterSet'
import { lotFilterSetBuilder } from '../Domain/lotFilterSetBuilder'

type Column = {|
  status: string,
  title: string,
  to: string[],
  sortBy: 'label' | 'status_changed',
  collapsable: boolean,
|}

type CustomerSummariesPayload = {
  customerId: string,
  firstName: string,
  lastName: ?string,
  email: string,
}

type CustomerInformations = {
  customerId: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  zipCode: string,
  address: string,
  city: string,
}

const COLUMNS = [
  {
    status: 'unavailable',
    title: 'Indisponibles',
    to: ['available'],
    sortBy: 'label',
    collapsable: true,
  },
  {
    status: 'available',
    title: 'Disponibles',
    to: ['unavailable', 'optioned', 'booked'],
    sortBy: 'label',
    collapsable: false,
  },
  {
    status: 'optioned',
    title: 'Optionnés',
    to: ['booked', 'available'],
    sortBy: 'status_changed',
    collapsable: false,
  },
  {
    status: 'booked',
    title: 'Réservés',
    to: ['sold', 'available'],
    sortBy: 'status_changed',
    collapsable: false,
  },
  {
    status: 'sold',
    title: 'Vendus',
    to: ['available', 'booked'],
    sortBy: 'status_changed',
    collapsable: false,
  },
]

type LotAssociationStep =
  | 'customer_selection'
  | 'customer_informations'
  | 'financial_informations'

export default class LotsStore {
  +rootStore: RootStore
  +columns: Column[]

  @observable programId: ?string = null
  @observable lots: Lot[] = []
  @observable filterSet: FilterSet<Lot> = FilterSet.fromFilterDefinitions([])
  @observable vat20: boolean = true
  @observable lotEditing: ?{
    lot: Lot,
    customer: ?CustomerInformations,
    errors: { source: string, message: string }[],
  } = null
  @observable lotAssociation: ?{
    lot: Lot,
    nextStatus: LotStatus,
    step: LotAssociationStep,
    customer: ?CustomerInformations,
    customerSummaries: CustomerSummariesPayload[],
    errors: { source: string, message: string }[],
  } = null

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.columns = COLUMNS
  }

  @action async fetchLotsByProgram(programId: string) {
    const response = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${programId}/lots`,
    )

    runInAction(() => {
      this.programId = programId

      this.lots = response.data.map(
        oneLotPayload =>
          new Lot(
            oneLotPayload.lotId,
            oneLotPayload.status,
            new Date(oneLotPayload.statusChangedAt),
            oneLotPayload.label,
            oneLotPayload.priceIncludingTaxes20,
            oneLotPayload.orientation,
            oneLotPayload.area,
            oneLotPayload.typology,
            oneLotPayload.floorNumber,
            oneLotPayload.commercialOffer,
          ),
      )

      this.resetFilterSet()
    })
  }

  @action async updateLotStatus(lotId: string, status: LotStatus) {
    const lot = this.findLotById(lotId)

    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${this.programId}/lot/${lotId}/status`,
      {
        status: status,
        commercialOffer: lot.customer
          ? {
              vat: lot.vat,
              loan: lot.loan,
              customerId: lot.customer.customerId,
            }
          : null,
      },
    )

    runInAction(() => {
      // FIXME: Move the commercial offer cleanup in the Lot
      if (status === 'available') {
        if (
          (this.vat20 && lot.vat === 5.5) ||
          (!this.vat20 && lot.vat === 20)
        ) {
          lot.switchVat()
        }

        lot.resetCommercialOffer()
      }

      lot.updateStatus(status)
    })
  }

  @action async updateLotAssociation(
    vat: LotVat,
    loan: LotLoan,
    status: LotStatus,
  ) {
    const lotAssociation = this.lotAssociation
    if (!lotAssociation) {
      throw new Error('No lot current associating')
    }
    const lotCustomer = lotAssociation.customer
    if (!lotCustomer) {
      throw new Error('No customer current associating')
    }

    const lot = this.findLotById(lotAssociation.lot.lotId)

    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${this.programId}/lot/${lot.lotId}/status`,
      {
        status: status,
        commercialOffer: {
          vat: vat,
          loan: loan,
          customerId: lotCustomer.customerId,
        },
      },
    )

    this.closeAssociationModal()

    runInAction(() => {
      lot.updateStatus(status)
      lot.updateCommercialOffer(
        vat,
        loan,
        lotCustomer.customerId,
        lotCustomer.firstName,
        lotCustomer.lastName,
      )
    })
  }

  @action async editFinancialInformations(
    vat: LotVat,
    loan: LotLoan,
    status: LotStatus,
  ) {
    if (!this.lotEditing) {
      throw new Error('No lot current editing')
    }

    const lotCustomer = this.lotEditing.customer
    if (!lotCustomer) {
      throw new Error('No customer current editing')
    }

    const lot = this.findLotById(this.lotEditing.lot.lotId)

    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    await this.rootStore.authenticationStore.httpClient.put(
      `/api/seller/program/${this.programId}/lot/${lot.lotId}/status`,
      {
        status: status,
        commercialOffer: {
          vat: vat,
          loan: loan,
          customerId: lotCustomer.customerId,
        },
      },
    )

    this.closeEditModal()

    runInAction(() => {
      lot.updateStatus(status)
      lot.updateFinancialInformations(vat, loan)
    })
  }

  @action resetFilterSet() {
    this.filterSet = lotFilterSetBuilder()
  }

  @action updateFilters(id: string, value: any) {
    this.filterSet = this.filterSet.toogleFilterValue(id, value)
  }

  @computed get filters(): FilterDescription[] {
    return this.filterSet.describe()
  }

  @computed get atLeastOneFilterIsActive() {
    return this.filterSet.atLeastOneFilterIsActive()
  }

  @computed get visibleLots() {
    return this.filterSet.filter(this.lots)
  }

  @computed get lotsByStatus(): { [keys: string]: Lot[] } {
    const lotsByStatus = {}

    this.columns.forEach(({ status, sortBy }) => {
      lotsByStatus[status] = this.visibleLots
        .filter(oneLot => oneLot.status === status)
        .sort(
          sortBy === 'label'
            ? this._sortLotsByLabel
            : this._sortLotsByStatusChanged,
        )
    })

    return lotsByStatus
  }

  findLotById = (lotId: string): Lot => {
    const lot = this.lots.find(lot => lot.is(lotId))
    if (!lot) {
      throw new Error(`Lot "${lotId}" not found`)
    }

    return lot
  }

  @action async openEditModal(lotId: string) {
    const lot = this.findLotById(lotId)
    if (!lot.isEditable) {
      throw new Error(
        `The lot ${lot.label} is not editable. Id: "${lot.lotId}"`,
      )
    }

    this.lotEditing = {
      lot: lot,
      errors: [],
      customer: null,
    }

    if (lot.customer) {
      if (!this.programId) {
        throw new Error('No program id linked to this store')
      }

      const customerResponse = await this.rootStore.authenticationStore.httpClient.get(
        `/api/seller/program/${this.programId}/customers/${lot.customer.customerId}`,
      )

      const customer = customerResponse.data
      runInAction(() => {
        if (this.lotEditing) {
          this.lotEditing.customer = {
            customerId: customer.customerId,
            firstName: customer.firstName,
            lastName: customer.lastName,
            email: customer.email,
            phone: customer.phone,
            zipCode: customer.zipCode,
            address: customer.street,
            city: customer.city,
          }
        }
      })
    }
  }

  @action async openModalAssociation(lotId: string, nextStatus: LotStatus) {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    const customersResponse = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${this.programId}/customers/summaries`,
    )

    runInAction(() => {
      this.lotAssociation = {
        lot: this.findLotById(lotId),
        nextStatus: nextStatus,
        step: 'customer_selection',
        customer: null,
        customerSummaries: customersResponse.data,
        errors: [],
      }
    })
  }

  @action onNewCustomer = () => {
    if (!this.lotAssociation) {
      throw new Error('No lot current associating')
    }
    this.lotAssociation.step = 'customer_informations'
  }

  @action async onCustomerSelected(customerId: string) {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    const lotAssociation = this.lotAssociation
    if (!lotAssociation) {
      throw new Error('No lot current associating')
    }

    lotAssociation.step = 'customer_informations'

    const customerResponse = await this.rootStore.authenticationStore.httpClient.get(
      `/api/seller/program/${this.programId}/customers/${customerId}`,
    )

    const customer = customerResponse.data
    runInAction(() => {
      lotAssociation.customer = {
        customerId: customer.customerId,
        firstName: customer.firstName,
        lastName: customer.lastName,
        email: customer.email,
        phone: customer.phone,
        zipCode: customer.zipCode,
        address: customer.street,
        city: customer.city,
      }
    })
  }

  @action async editCustomerInformations({
    emailValue,
    firstNameValue,
    lastNameValue,
    phoneValue,
    addressValue,
    zipCodeValue,
    cityValue,
  }: {
    emailValue: string,
    firstNameValue: string,
    lastNameValue: string,
    phoneValue: string,
    addressValue: string,
    zipCodeValue: string,
    cityValue: string,
  }) {
    let lotEditing = this.lotEditing
    if (!lotEditing) {
      throw new Error('No lot current editing')
    }

    const lotCustomer = lotEditing.customer
    if (!lotCustomer) {
      throw new Error('No customer current editing')
    }

    const lot = this.findLotById(lotEditing.lot.lotId)

    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    try {
      await this.rootStore.authenticationStore.httpClient.put(
        `/api/seller/program/${this.programId}/customer/${lotCustomer.customerId}`,
        {
          firstName: firstNameValue,
          lastName: lastNameValue,
          email: emailValue,
          phone: phoneValue,
          street: addressValue,
          zipCode: zipCodeValue,
          city: cityValue,
        },
      )

      this.closeEditModal()

      runInAction(() => {
        lot.updateCustomer(firstNameValue, lastNameValue)
      })
    } catch (err) {
      runInAction(() => {
        lotEditing.errors = err.response.data
      })
    }
  }

  @action async onCustomerValidate({
    emailValue,
    firstNameValue,
    lastNameValue,
    phoneValue,
    addressValue,
    zipCodeValue,
    cityValue,
  }: {
    emailValue: string,
    firstNameValue: string,
    lastNameValue: string,
    phoneValue: string,
    addressValue: string,
    zipCodeValue: string,
    cityValue: string,
  }) {
    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }
    const lotAssociation = this.lotAssociation
    if (!lotAssociation) {
      throw new Error('No lot current associating')
    }

    if (lotAssociation.customer && lotAssociation.customer.customerId) {
      const customerId = lotAssociation.customer.customerId
      try {
        await this.rootStore.authenticationStore.httpClient.put(
          `/api/seller/program/${this.programId}/customer/${customerId}`,
          {
            firstName: firstNameValue,
            lastName: lastNameValue,
            email: emailValue,
            phone: phoneValue,
            street: addressValue,
            zipCode: zipCodeValue,
            city: cityValue,
          },
        )

        runInAction(() => {
          lotAssociation.step = 'financial_informations'
          lotAssociation.customer = {
            customerId: customerId,
            firstName: firstNameValue,
            lastName: lastNameValue,
            email: emailValue,
            phone: phoneValue,
            address: addressValue,
            zipCode: zipCodeValue,
            city: cityValue,
          }
        })
      } catch (err) {
        runInAction(() => {
          lotAssociation.errors = err.response.data
        })
      }
    } else {
      try {
        const response = await this.rootStore.authenticationStore.httpClient.post(
          `/api/seller/program/${this.programId}/customer`,
          {
            firstName: firstNameValue,
            lastName: lastNameValue,
            email: emailValue,
            phone: phoneValue,
            street: addressValue,
            zipCode: zipCodeValue,
            city: cityValue,
          },
        )

        runInAction(() => {
          lotAssociation.customer = {
            customerId: response.data.customerId,
            firstName: firstNameValue,
            lastName: lastNameValue,
            email: emailValue,
            phone: phoneValue,
            address: addressValue,
            zipCode: zipCodeValue,
            city: cityValue,
          }
          lotAssociation.step = 'financial_informations'
        })
      } catch (err) {
        runInAction(() => {
          lotAssociation.errors = err.response.data
        })
      }
    }
  }

  @action closeAssociationModal = () => {
    this.lotAssociation = null
  }

  @action closeEditModal = () => {
    this.lotEditing = null
  }

  @action async editPrice(priceIncludingTaxes20: number) {
    if (!this.lotEditing) {
      throw new Error('No lot current editing')
    }

    const lot = this.findLotById(this.lotEditing.lot.lotId)

    if (!this.programId) {
      throw new Error('No program id linked to this store')
    }

    try {
      await this.rootStore.authenticationStore.httpClient.put(
        `/api/seller/program/${this.programId}/lot/${lot.lotId}/price`,
        {
          priceIncludingTaxes20,
        },
      )
      this.closeEditModal()

      runInAction(() => {
        lot.updatePrice(priceIncludingTaxes20)
      })
    } catch (err) {
      runInAction(() => {
        if (this.lotEditing) {
          this.lotEditing.errors = err.response.data
        }
      })
    }
  }

  @action switchVat = () => {
    this.vat20 = !this.vat20
    this.lots.forEach(lot => {
      if (lot.status === 'available') {
        lot.switchVat()
      }
    })
  }

  _sortLotsByLabel(lotA: Lot, lotB: Lot): -1 | 0 | 1 {
    return lotA.label < lotB.label ? -1 : 1
  }

  _sortLotsByStatusChanged(lotA: Lot, lotB: Lot): -1 | 0 | 1 {
    return lotA.statusChangedAt > lotB.statusChangedAt ? -1 : 1
  }
}
