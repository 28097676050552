// @flow

import * as React from 'react'
import { configure } from 'mobx'
import { Provider } from 'mobx-react'
import DevTools from 'mobx-react-devtools'
import { buildRootStore } from './RootStore'
import mobxRouterBuilder from './Router'
import routes from './Routes'
import { DragDropContext, InfiniteLoader } from '@custhome/seller-guidelines'
import PageNotFound from './Ui/PageNotFound'

const API_ENDPOINT = process.env.API_ENDPOINT
if (!API_ENDPOINT) {
  throw new Error('The environment variable "API_ENDPOINT" is missing or empty')
}

const OAUTH_CLIENT_ID = process.env.OAUTH_CLIENT_ID
if (!OAUTH_CLIENT_ID) {
  throw new Error(
    'The environment variable "OAUTH_CLIENT_ID" is missing or empty',
  )
}

const OAUTH_CLIENT_SECRET = process.env.OAUTH_CLIENT_SECRET
if (!OAUTH_CLIENT_SECRET) {
  throw new Error(
    'The environment variable "OAUTH_CLIENT_SECRET" is missing or empty',
  )
}

configure({ enforceActions: 'always' })

const rootStore = buildRootStore(
  API_ENDPOINT,
  OAUTH_CLIENT_ID,
  OAUTH_CLIENT_SECRET,
)

rootStore.authenticationStore.loadTokenFromStorage()
rootStore.routerStore.mountRouter(routes)

const ApplicationRouter = mobxRouterBuilder('routerStore')

export default () => (
  <Provider
    rootStore={rootStore}
    uiStore={rootStore.uiStore}
    routerStore={rootStore.routerStore}
    userStore={rootStore.userStore}
    authenticationStore={rootStore.authenticationStore}
    programsStore={rootStore.programsStore}
    lotsStore={rootStore.lotsStore}
  >
    <React.Fragment>
      <DragDropContext>
        <ApplicationRouter
          loading={<InfiniteLoader />}
          notFound={<PageNotFound />}
        />
      </DragDropContext>

      {process.env.NODE_ENV === 'development' ? <DevTools /> : null}
    </React.Fragment>
  </Provider>
)
