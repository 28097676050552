// @flow

import * as React from 'react'
import styled from 'styled-components'
import {
  Colors,
  CusthomePictoLogo,
  NativeLink,
  RobotoMedium,
} from '@custhome/seller-guidelines'

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`

const Text = styled.p`
  font-family: ${RobotoMedium.fontFamily};
  font-size: 16px;
  font-weight: ${RobotoMedium.fontWeight};
  color: ${Colors.blackUltraBold};
  line-height: 1.4;
`

export default () => (
  <LoaderContainer>
    <CusthomePictoLogo color={Colors.blackUltraBold} width="64px" />
    <Text>
      Oups, cette page n'existe pas !
      <br />
      Merci de <NativeLink href="/">retourner à l'accueil</NativeLink>
    </Text>
  </LoaderContainer>
)
