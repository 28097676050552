// @flow

import { action } from 'mobx'
import { RootStore } from '../RootStore'
import routes from '../Routes'
import { Route } from 'mobx-router'

class UiStore {
  +rootStore: RootStore

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
  }

  @action redirectToLoginPage(
    previousRoute?: Route,
    previousParams?: { [keys: string]: any },
    previousQueryArguments?: { [keys: string]: any },
  ) {
    this.rootStore.routerStore.goTo(routes['login_page'], {
      previousRoute,
      previousParams,
      previousQueryArguments,
    })
  }
}

export default UiStore
