// @flow

import {
  RouterStore as OriginalRouterStore,
  Route,
  startRouter,
} from 'mobx-router'
import { RootStore } from '../RootStore'
import { computed, action, observable, runInAction } from 'mobx'

export { Route }

export default class RouterStore {
  +rootStore: RootStore
  +originalRouterStore: OriginalRouterStore

  @observable pageNotFound = false

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore
    this.originalRouterStore = new OriginalRouterStore()

    this.rootStore.router = this.originalRouterStore
  }

  mountRouter(routes: { [keys: string]: Route }) {
    startRouter(routes, this.rootStore, {
      notfound: this.onRouteNotFound.bind(this),
      on: this.onRouteChanged.bind(this),
    })
  }

  @action onRouteNotFound() {
    runInAction(() => {
      this.pageNotFound = true
    })
  }

  @action onRouteChanged() {
    runInAction(() => {
      this.pageNotFound = false
    })
  }

  @action async goTo(
    view: Route,
    params: { [keys: string]: any } = {},
    queryParams: { [keys: string]: any } = {},
  ) {
    return this.originalRouterStore.goTo(
      view,
      params,
      this.rootStore,
      queryParams,
    )
  }

  @computed get currentPath() {
    return this.originalRouterStore.currentPath
  }

  @computed get params() {
    return this.originalRouterStore.params
  }

  @computed get queryParams() {
    return this.originalRouterStore.queryParams
  }

  @computed get currentView() {
    return this.originalRouterStore.currentView
  }
}
