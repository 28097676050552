// @flow

import * as React from 'react'
import { observer, inject } from 'mobx-react'
import { RouterStore } from 'mobx-router'

type ApplicationRouterProps = {|
  notFound: React.Node,
  loading: React.Node,
|}

const ApplicationRouter = ({
  routerStore,
  notFound,
  loading,
}: {
  routerStore: RouterStore,
  ...ApplicationRouterProps,
}) => {
  const { currentView } = routerStore
  const currentRouteComponent =
    currentView && currentView.component ? currentView.component : null

  if (routerStore.pageNotFound) {
    return notFound
  }

  if (!currentRouteComponent) {
    return loading
  }

  return currentRouteComponent
}

export default (
  registeredRouterStoreKey: string,
): React.ComponentType<ApplicationRouterProps> =>
  inject(registeredRouterStoreKey)(observer(ApplicationRouter))
