// @flow

export default class Program {
  id: string
  label: string
  location: string
  totalNumberOfLot: number

  constructor(
    id: string,
    label: string,
    location: string,
    totalNumberOfLot: number,
  ) {
    this.id = id
    this.label = label
    this.location = location
    this.totalNumberOfLot = totalNumberOfLot
  }
}
