// @flow

import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'

export const initErrorReportingAgent = (
  dsn: string,
  buildEnvironment: string,
  applicationName: string,
  applicationVersion: string,
) => {
  Sentry.init({
    dsn,
    environment: buildEnvironment,
    release: `${applicationName}@${applicationVersion}`,
    integrations: [new Integrations.ExtraErrorData()],
  })
}
